:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

.fui-FluentProviderr3 {
    --colorCompoundBrandStroke: #576ddd !important;
    --colorBrandBackground: #576ddd !important;
    --colorBrandStroke2Contrast: #576ddd !important;
    --colorBrandStroke1: #030f2b !important;
    --fontFamilyBase: 'LVMH Sans';
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-extralight.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-extralight.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: italic;
    font-weight: 200;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-extralightitalic.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-extralightitalic.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-light.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-light.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-lightitalic.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-lightitalic.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-regular.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-regular.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-italic.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-italic.woff') format('woff');
}

@font-face {
    font-family: 'LVMH Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-bold.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-bold.woff') format('woff');
}
@font-face {
    font-family: 'LVMH Sans';
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src:
        url('./assets/fonts/lvmhsansweb-bolditalic.woff2') format('woff2'),
        url('./assets/fonts/lvmhsansweb-bolditalic.woff') format('woff');
}

html * {
    font-family: 'LVMH Sans', sans-serif !important;
}

body {
    font-family: 'LVMH Sans', sans-serif !important;
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: hidden;
}

.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
}

.box {
    transition: opacity 0.5s ease;
    color: #fff;
    font-weight: 700;
    margin-bottom: 3vh;
    margin-top: 0;
}

.box p {
    font-size: 4vh;
    font-weight: 700;
    line-height: 5vh;
    margin-bottom: 5px;
    margin-top: 0;
}

.box p + p {
    font-size: 3vh;
    margin-bottom: 0;
}

@media (max-width: 744px) {
    .box p {
        font-size: 4vh;
        line-height: 3vh;
    }

    .box p + p {
        font-size: 2vh;
    }
}

.opacity-50 {
    opacity: 0.1;
    transition: opacity 0.5s ease;
}

.opacity-70 {
    opacity: 0.5;
    transition: opacity 0.5s ease;
}

.hide {
    display: none;
}

/* By default, all mobile browsers force
the form element to work as it is.
But when a developer sets the font size
less than 16 pixels on any form
element – mobile browsers intervene and
forces the UI or Page to zoom so that the
texts are readable enough. */
@media (max-width: 767px) {
    input,
    select,
    textarea {
        font-size: 16px !important;
    }
}

pre {
    white-space: pre-wrap;
    max-width: 100%;
    height: auto;
}

[data-fui-focus-visible] {
    box-shadow: none !important;
}

:focus-visible {
    outline: none;
}

.svg-container {
    color: #576ddd;
    transition: color 0.3s ease;
    display: flex;
    align-items: center;
}

.svg-container:not(.no-hover):hover {
    opacity: 0.5;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        visibility: visible;
    }
    to {
        opacity: 0;
        visibility: hidden;
    }
}

.fade-in {
    -webkit-animation-name: fadeIn !important;
    animation-name: fadeIn !important;

    -webkit-animation-duration: 0.5s !important;
    animation-duration: 0.5s !important;

    -webkit-animation-timing-function: ease !important;
    animation-timing-function: ease !important;

    -webkit-animation-fill-mode: forwards !important;
    animation-fill-mode: forwards !important;
}

.fade-out {
    -webkit-animation-name: fadeOut !important;
    animation-name: fadeOut !important;

    -webkit-animation-duration: 0.5s !important;
    animation-duration: 0.5s !important;

    -webkit-animation-timing-function: ease !important;
    animation-timing-function: ease !important;

    -webkit-animation-fill-mode: forwards !important;
    animation-fill-mode: forwards !important;
}

.thumbsIcon.selected path {
    fill: #576ddd;
}

.thumbsIcon.disabled path {
    opacity: 0.5;
}

.starIcon {
    display: flex;
    align-items: center;
}

.starIcon.disabled path {
    opacity: 0.5;
}

.fade {
    position: sticky;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(247, 243, 237));
    height: 50px;
    margin-top: -50px;
    width: 100%;
    pointer-events: none;
    z-index: 2;
}

.new-title {
    background: linear-gradient(90deg, #576ddd 0%, #030f2b 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    height: 40px;
    line-height: 40px;
}

.new-subtitle {
    color: #030f2b;
    font-size: 20px;
    font-weight: 300;
}

.new-subtitle span {
    cursor: pointer;
    text-decoration: underline;
}

.tooltip-style {
    background: #030f2b !important;
    color: #fff !important;
}

*[data-fui-focus-visible]::after {
    display: none;
}

.wizard-shadow:after {
    position: fixed;
    content: '';
    background: #000;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.7;
}

.shadow-zindex {
    z-index: 10000 !important;
    position: relative;
}

.popover1 {
    border-radius: 5px;
    background: #f7f3ed;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

#popover3 {
    z-index: 10000;
    position: relative;
}

.fui-FluentProvider {
    z-index: 9999 !important;
}

.drag-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    transition: opacity 0.3s ease;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    pointer-events: none;
}

.drag-overlay.visible {
    opacity: 0.6;
    pointer-events: auto;
}

.modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 744px) {
        width: 260px;
    }
}

#root .modalContainer:hover img {
    outline: 2px solid #576ddd;
    outline-offset: -2px;
}

.downloadButton,
.favoriteButton,
.evaluateButton,
.reusePromptButton {
    z-index: 3;
    display: flex;
    height: 32px;
    max-height: 32px;
    box-sizing: border-box;
    position: absolute;
    text-transform: uppercase;
    border-radius: 32px;
    border: 1px solid #9cb0ec;
    padding: 5px 8px;
    color: #576ddd;
    font-weight: 400;
    font-size: 14px;
    background-color: #ffffff;
    align-items: center;
    gap: 0;
    transition: gap 0.3s ease;
}

.downloadButton span,
.favoriteButton span,
.evaluateButton span,
.reusePromptButton span {
    max-width: 0;
    overflow: hidden;
    white-space: nowrap;
    transition: max-width 0.3s ease;
}

@media (min-width: 745px) {
    .downloadButton,
    .favoriteButton,
    .evaluateButton,
    .reusePromptButton {
        display: none;
    }

    .modalContainer:hover .downloadButton,
    .modalContainer:hover .favoriteButton,
    .modalContainer:hover .evaluateButton,
    .modalContainer:hover .reusePromptButton {
        display: flex;
    }

    .modalContainer .downloadButton:hover,
    .modalContainer .favoriteButton:hover,
    .modalContainer .evaluateButton:hover,
    .modalContainer .reusePromptButton:hover {
        gap: 5px;
    }

    .modalContainer .downloadButton:hover span,
    .modalContainer .favoriteButton:hover span,
    .modalContainer .evaluateButton:hover span,
    .modalContainer .reusePromptButton:hover span {
        max-width: 200px;
    }
}

@media (min-width: 1366px) {
    .modalContainer .downloadButton:not(.mini),
    .modalContainer .favoriteButton:not(.mini),
    .modalContainer .evaluateButton:not(.mini),
    .modalContainer .reusePromptButton:not(.mini) {
        gap: 5px;
    }

    .modalContainer .downloadButton:not(.mini) span,
    .modalContainer .favoriteButton:not(.mini) span,
    .modalContainer .evaluateButton:not(.mini) span,
    .modalContainer .reusePromptButton:not(.mini) span {
        max-width: 200px;
    }
}

@media (max-width: 744px) {
    .downloadButton.mobile,
    .favoriteButton.mobile,
    .evaluateButton.mobile,
    .reusePromptButton.mobile {
        display: none;
    }

    .modalContainer.mobile.displayed .downloadButton,
    .modalContainer.mobile.displayed .favoriteButton,
    .modalContainer.mobile.displayed .evaluateButton,
    .modalContainer.mobile.displayed .reusePromptButton {
        display: flex;
    }
}

.downloadButton.mini {
    top: 15px;
    right: 15px;
    height: 34px;
    min-height: 34px;
}

.favoriteButton.mini {
    bottom: 15px;
    right: 15px;
    height: 34px;
    min-height: 34px;
}

.evaluateButton.mini {
    bottom: 15px;
    left: 15px;
    height: 34px;
    min-height: 34px;
}

.reusePromptButton.mini {
    top: 15px;
    left: 15px;
    height: 34px;
    min-height: 34px;
}

.downloadButton {
    top: 15px;
    right: 25px;
}

.favoriteButton {
    bottom: 15px;
    right: 25px;
}

.evaluateButton {
    bottom: 15px;
    left: 25px;
}

.reusePromptButton {
    top: 15px;
    left: 25px;
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOutToRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

@media (max-width: 744px) {
    .new-title {
        height: 80px;
    }
    .fade {
        display: none;
    }

    .carousel-image {
        min-height: 100px;
        min-width: 200px;
        animation-duration: 1s;
    }

    .slide-enter-left {
        animation-name: slideInFromLeft;
    }

    .slide-exit-left {
        animation-name: slideOutToLeft;
    }

    .slide-enter-right {
        animation-name: slideInFromRight;
    }

    .slide-exit-right {
        animation-name: slideOutToRight;
    }
}

@media (min-width: 744px) {
    .welcomeButtons_desktopVisible {
        display: flex;
    }
    .welcomeButtons_desktopHidden {
        display: none;
    }
}

@media (max-width: 744px) {
    .welcomeButtons_mobileVisible {
        display: flex;
    }

    .welcomeButtons_mobileHidden {
        display: none;
    }
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

.code-of-use-content {
    position: relative;
}

.code-of-use-content::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}
.code-of-use-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.code-of-use-content::after {
    content: '';
    position: sticky;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(255, 255, 255));
    height: 50px;
    margin-top: -50px;
    width: 100%;
    pointer-events: none;
    display: block;
}

.katex {
    font-size: 20px !important;
}

.prompts-dialog-value:hover {
    box-shadow: inset 0 0 0 1px #dbd7d2 !important;
    border-radius: 16px !important;
    background: rgba(219, 215, 210, 0.6) !important;
    text-decoration: underline;
}
.prompts-dialog-value:hover .no-underline {
    display: inline-block;
    text-decoration: none;
}

.prompts-dialog-value .prompts-dialog-value__btn {
    display: none;
}

.prompts-dialog-value:hover .prompts-dialog-value__btn {
    display: inline-block;
    min-width: auto;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    vertical-align: baseline;
}

.prompts-dialog-title {
    display: inline-block;
    width: fit-content;
    padding: 8px;
}

.prompts-dialog-title:hover {
    box-shadow: inset 0 0 0 1px #dbd7d2;
    border-radius: 8px;
    background: rgba(219, 215, 210, 0.6);
    text-decoration: underline;
}

.prompts-dialog-title:hover .no-underline {
    display: inline-block;
    text-decoration: none;
}

.prompts-dialog-title .prompts-dialog-title__btn {
    display: none;
}

.prompts-dialog-title:hover .prompts-dialog-title__btn {
    display: inline-block;
    min-width: auto;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    vertical-align: baseline;
}

.prompts-dialog-description:hover {
    box-shadow: inset 0 0 0 1px #dbd7d2 !important;
    border-radius: 16px !important;
    background: rgba(219, 215, 210, 0.6) !important;
    text-decoration: underline;
}

.prompts-dialog-description:hover .no-underline {
    display: inline-block;
    text-decoration: none;
}

.prompts-dialog-description .prompts-dialog-description__btn {
    display: none;
}

.prompts-dialog-description:hover .prompts-dialog-description__btn {
    display: inline-block;
    min-width: auto;
    height: 24px;
    width: 24px;
    margin-left: 8px;
    vertical-align: baseline;
}

.CssTextFieldEditTitle {
    position: relative;
    width: 100%;
}

.CssTextFieldEditTitle .MuiOutlinedInput-input {
    padding-right: 70px !important;
}

.CssTextFieldEditTitle .CssTextFieldEditTitleDelete {
    position: absolute;
    top: 12px;
    right: 40px;
    min-width: auto;
}

.CssTextFieldEditTitle .CssTextFieldEditTitleSave {
    position: absolute;
    top: 12px;
    right: 10px;
    min-width: auto;
}

.CssTextFieldEditPrompt {
    position: relative;
}

.CssTextFieldEditPrompt .MuiOutlinedInput-root {
    padding-right: 70px !important;
}

.CssTextFieldEditPrompt .CssTextFieldEditPromptDelete {
    position: absolute;
    bottom: 12px;
    right: 40px;
    min-width: auto;
}

.CssTextFieldEditPrompt .CssTextFieldEditPromptSave {
    position: absolute;
    bottom: 12px;
    right: 10px;
    min-width: auto;
}

.CssTextFieldEditNote {
    position: relative;
}

.CssTextFieldEditNote .MuiOutlinedInput-root {
    padding-right: 70px !important;
}

.CssTextFieldEditNote .CssTextFieldEditNoteDelete {
    position: absolute;
    bottom: 12px;
    right: 40px;
    min-width: auto;
}

.CssTextFieldEditNote .CssTextFieldEditNoteSave {
    position: absolute;
    bottom: 12px;
    right: 10px;
    min-width: auto;
}

.CssTextFieldEdit {
    position: relative;
    width: 100%;
}

.CssTextFieldEdit .MuiOutlinedInput-root {
    padding-bottom: 50px !important;
}

.CssTextFieldEdit .CssTextFieldEditDelete {
    position: absolute;
    bottom: 12px;
    right: 90px;
    min-width: auto;
}

.CssTextFieldEdit .CssTextFieldEditSave {
    position: absolute;
    bottom: 12px;
    right: 10px;
    min-width: auto;
}

pre code.hljs {
    padding: 3em 1em 1em;
}
