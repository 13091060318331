.button {
    cursor: pointer;
    margin-top: 8px;
    display: inline-flex;
    align-items: center;
    border: 1px solid #656b80;
    border-radius: 32px;
    gap: 4px;
    padding: 4px 16px;
    background-color: transparent;
    transition:
        background-color 0.3s ease,
        opacity 0.3s ease;
}

.button:hover {
    background-color: #e6e6e6;
    border: 1px solid #9aa0a0;
}

.button.loading {
    cursor: not-allowed;
    background-color: #f0f0f0;
    opacity: 0.6;
    pointer-events: none;
}
